import React from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import Body from "components/Body";
import Pattern6 from "components/Patterns/Pattern6";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";

export default function MayorHomeSection({ node }) {
  const { t } = useTranslation("home");

  return (
    <div className="relative pt-24 pb-28 lg:pt-32 lg:pb-44 lg:pl-16">
      <div className="hidden lg:block absolute top-0 left-0" aria-hidden="true">
        <Pattern6 className="text-secondary" />
      </div>

      <div
        className="hidden md:block absolute bottom-0 left-16 text-default text-[210px] font-important font-bold leading-none opacity-5 translate-y-1/4"
        aria-hidden="true"
      >
        {t("officials.decoration")}
      </div>

      <div className="relative">
        <div className="text-important uppercase text-secondary text-sm font-important font-medium tracking-wider mb-5 before:content-['///'] before:mr-2">
          {t("officials.pre_title")}
        </div>

        <h2 className="text-2xl font-important font-bold mb-8">
          {t("officials.title")}
        </h2>

        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-initial w-1/2 md:w-3/12">
            <Image
              src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${node.field_image.uri.url}`}
              width={130}
              height={130}
              layout="responsive"
              className="rounded-full"
              alt={node.field_image?.alt}
            />
          </div>

          <div className="flex-1">
            {node.body?.summary && (
              <div className="prose max-w-none">
                <Body value={node.body.summary} />
              </div>
            )}

            <Link href={t("common:official.path")} passHref>
              <a className="group mt-8 inline-flex items-center">
                <span className="text-semibold underline underline-offset-4 decoration-transparent group-hover:decoration-secondary transition-colors">
                  {t("officials.more")}
                </span>
                <ArrowRightIcon className="h-4 w-4 ml-2 text-secondary" />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
