import React from "react";

export default function Pattern6({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="350"
      height="71"
      viewBox="0 0 350 71"
      className={className}
    >
      <defs>
        <rect id="pattern6rect" width="350" height="71" x="0" y="0" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="pattern6mask" fill="#fff">
          <use xlinkHref="#pattern6rect" />
        </mask>
        <path
          fill="currentColor"
          d="M-571.765252,-539 L-580,-530.767441 L23.8282603,69 L28,64.8304641 L-571.765252,-539 Z M-571.767756,-569 L-580,-560.769975 L54.0136508,69 L58,65.0136508 L-571.767756,-569 Z M-571.769788,-599 L-580,-590.772033 L84.2364136,69 L88,65.2364261 L-571.769788,-599 Z M-564.807297,-622 L-573,-613.807297 L114.454931,69 L118,65.4571497 L-564.807297,-622 Z M-534.95157,-622 L-543,-613.95157 L144.678109,69 L148,65.6781087 L-534.95157,-622 Z M-504.219995,-622 L-512,-614.217778 L174.865833,68 L178,64.8636162 L-504.219995,-622 Z M-474.401697,-622 L-482,-614.401721 L205.084398,68 L208,65.0844071 L-474.401697,-622 Z M-443.61885,-622 L-451,-614.621068 L236.305259,68 L239,65.3052593 L-443.61885,-622 Z M-413.801015,-622 L-421,-614.801015 L266.490893,68 L269,65.490893 L-413.801015,-622 Z M-384.023018,-622 L-391,-615.020799 L296.674339,68 L299,65.6765585 L-384.023018,-622 Z M-353.250335,-621 L-360,-614.250357 L326.898627,68 L329,65.8964167 L-353.250335,-621 Z M-323.434611,-621 L-330,-614.434611 L357.117523,68 L359,66.1175227 L-323.434611,-621 Z M-292.616132,-621 L-299,-614.620566 L365.155131,45 L367,43.1551308 L-292.616132,-621 Z"
          mask="url(#pattern6mask)"
        />
      </g>
    </svg>
  );
}
